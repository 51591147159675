@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Monaco, Menlo, Consolas, 'Roboto Mono', monospace;
}

// For lack of a better place right now, here are some carousel overrides
.swiper {
  width: 100%;
  margin: 0;
}

.swiper-button-prev, .swiper-button-next {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0078D4;
  padding: 0;
  margin: 0;
  height: 100%;
  width: 50px;
  top: 0;
  left: 0;
  color: white;
}

.swiper-button-next {
  right: 0;
  left: unset;
}

@media screen and (-ms-high-contrast: active), (forced-colors: active) {
  // Ensure <FontAwesomeIcon> recolours with Windows High Contrast Mode in fashion that will be visible
  svg.svg-inline--fa path {
    fill: CanvasText;
    stroke: Canvas;
    stroke-width: 2.5%;
  }
  button, [role=button] {
    svg.svg-inline--fa path {
      fill: ButtonText;
      stroke: ButtonFace;
    }
    &:disabled svg.svg-inline--fa path {
      fill: GrayText;
    }
  }
}
