@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap);
#toast-container-main {
  position: fixed;
  top: 30px;
  right: 50px;
  z-index: 9999999;
  display: flex;
  flex-direction: column;
  min-width: 250px;
}

#toast-container-main > div {
  background-color: #323232;
  color: white;
  width: 100%;
  margin-bottom: 10px;
  min-height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 12px 12px 14px;
  animation: slide-in 0.2s;
  transition: all 0.3s ease;
  border-radius: 3px;
}

.toast-header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.toast-body {
  margin-top: 5px;
  font-size: 14px;
}

@keyframes slide-in {
  from {
    transform: translateX(30px);
    opacity: 0;
  }
  to {
    transform: translateX(0px);
    opacity: 1;
  }
}

#toast-container-main button {
  background: none;
  border: none;
  color: #fff;
  outline: none;
  cursor: pointer;
  border-radius: 6px;
  padding: 4px 8px;
}

#toast-container-main button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

@keyframes animateCallout{0%{transform:translateX(0)}50%{transform:translateX(-12px)}100%{transform:translateX(0)}}.navbarCallout{animation:animateCallout 1s infinite}
body{margin:0;font-family:-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}code{font-family:Monaco,Menlo,Consolas,"Roboto Mono",monospace}.swiper{width:100%;margin:0}.swiper-button-prev,.swiper-button-next{display:flex;align-items:center;justify-content:center;background-color:#0078d4;padding:0;margin:0;height:100%;width:50px;top:0;left:0;color:#fff}.swiper-button-next{right:0;left:unset}@media screen and (-ms-high-contrast: active),(forced-colors: active){svg.svg-inline--fa path{fill:CanvasText;stroke:Canvas;stroke-width:2.5%}button svg.svg-inline--fa path,[role=button] svg.svg-inline--fa path{fill:ButtonText;stroke:ButtonFace}button:disabled svg.svg-inline--fa path,[role=button]:disabled svg.svg-inline--fa path{fill:GrayText}}
